export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: '2rem',
      height: 'auto'
    },

    '.containerScrolled': {
      padding: '1rem',
      background: 'unset',
      border: 'none',
      '.smallNavMenu': {
        '.navItem': {
          a: {
            color: 'white',
            textShadow: '2px 2px 10px black'
          }
        }
      },
      '.hamburger, hamburgerOpen': {
        borderColor: 'primary',
        backgroundColor: 'rgba(255,255,255,0.4)',
        '> div': {
          backgroundColor: 'primary'
        }
      }
    },
    '.logo': {
      img: {
        filter: 'unset',
        maxHeight: '70px'
      }
    },
    '.logoScrolled': {
      display: 'none'
    },
    '.hamburger': {
      order: '4',
      border: 'solid 1px white',
      padding: '0.5rem',
      height: '40px',
      width: '50px',
      borderRadius: '10px',
      '> div': {
        backgroundColor: 'white'
      }
    },
    '.hamburgerOpen': {
      order: '4'
    },
    '.navLogo': {
      position: 'static'
    },
    '.navLogoScrolled': {
      display: 'none'
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenu, .navMenuOpen': {
      transform: 'unset'
    }
  },

  footer: {
    padding: '1rem',
    '.logo': {
      // filter: 'brightness(0) invert(1)',
      marginBottom: '1.5rem'
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    '.multiButtonContainer': {
      a: {
        variant: 'buttons.secondary',
        margin: '0.5rem',
        width: ['', '', '', 'calc(50% - 1rem)']
      }
    },
    '.gonationLogo': {
      width: 'fit-content',
      filter: 'brightness(0) invert(1)'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'text',
    order: '1',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    transform: 'none!important'
  },
  subtitle: {
    color: 'secondary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.4rem', '2.5rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    textShadow: '2px 2px 10px black',
    fontWeight: 'bold'
  },
  text: {
    textAlign: 'center',
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',
      textAlign: 'center',
      fontSize: 'inherit'
    }
  },

  // sideBySide1: {
  //   padding: '1rem',
  //   '.lazyload-wrapper': {
  //     maxHeight: '75vh',
  //   },
  //   '.content': {
  //     // variant: 'customVariants.borderCorners',
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     margin: ['1rem 0rem', '', '0rem 1rem'],
  //   },
  //   '.title': {
  //     variant: 'customVariants.title',
  //   },
  //   '.subtitle': { variant: 'customVariants.subtitle' },
  //   '.text': {
  //     variant: 'customVariants.text',
  //   },
  //   a: {
  //     order: '4',
  //   },
  //   '.ctaButton': {
  //     variant: 'buttons.secondary',
  //   },
  // },

  // sideBySide2: {
  //   variant: 'customVariants.sideBySide1',
  //   '.imageContainer': {
  //     height: ['', '', '75vh'],
  //   },
  //   '.content': {
  //     // variant: 'customVariants.borderCorners',
  //     order: '2',
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     margin: ['1rem 0rem', '', '0rem 1rem'],
  //   },
  //   a: {
  //     order: '4',
  //   },
  // },

  heroBlock1: {
    marginTop: '1rem',
    '.section': {
      margin: 'auto auto auto 0rem',
      padding: '0.5rem',
      width: '100%',
      // maxWidth: 'unset',
      alignItems: 'flex-start',
      padding: ['2rem', '', '', '3rem']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'left',
      width: '100%',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'left',
      width: '100%'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'left',
      width: '100%',
      p: {
        color: 'white',
        textAlign: 'left'
      }
    },
    '.gatsby-link': {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  heroBlock2: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
    '.lazyload-wrapper': {
      width: ['', '', '', '60%'],
      m: ['2rem 1rem', '', '3rem 1rem', '4rem 1rem']
    },
    '.content': {
      width: ['', '', '', '40%'],
      // backgroundColor: ['', '', '', '#e7e7e7b5'],
      // margin: ['', '', '', '0rem 0rem 0rem auto'],
      // alignItems: ['', '', '', 'flex-start'],
      textAlign: ['', '', '', 'left'],
      padding: ['', '', '', '3rem']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 2rem',
      padding: '0rem',
      textAlign: ['center', '', '', 'left'],
      color: ['white', '', '', 'black']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: ['', '', '', 'left'],
      fontSize: ['1.5rem', '1.4rem', '2.5rem'],
      textShadow: ['', '', '', 'none']
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      color: ['white', '', '', 'primary'],
      p: {
        lineHeight: '1.75',
        fontWeight: '600',
        color: ['white', '', '', 'primary'],
        textAlign: ['', '', '', 'left']
      }
    },
    '.gatsby-link': {
      order: '4'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    variant: 'customVariants.heroBlock1',
    '.ctaButton': {
      order: 4
    }
  },

  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.heroBlock2',
    marginTop: '0rem'
  },

  homepageMenu: {
    variant: 'customVariants.heroBlock1',
    marginTop: '0rem'
  },

  homepageGallery: {
    variant: 'customVariants.heroBlock2',
    marginTop: '0rem',
    // switch block around
    '.section': {
      width: ['', '', '', '40%'],
      backgroundColor: ['', '', '', '#e7e7e7b5'],
      margin: ['', '', '', '0rem auto 0rem 0rem'],
      alignItems: ['', '', '', 'flex-start'],
      textAlign: ['', '', '', 'left'],
      padding: ['', '', '', '3rem']
    }
  },
  homepageEvents: {
    variant: 'customVariants.heroBlock1',
    marginTop: '0rem'
  },

  homepageContact: {
    variant: 'customVariants.heroBlock2',
    marginTop: '0rem'
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'secondary',
      fontFamily: 'body',
      borderRadius: '20px'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'bold',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['primary', '', '', 'primary'],
      color: 'white',
      textTransform: 'uppercase',
      borderRadius: '20px'
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      borderRadius: '20px'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      borderRadius: '20px',
      img: {
        borderRadius: '20px'
      }
    },

    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      color: 'white',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: '#562316',
    '.subtitle': {
      color: '#c12124',
      backgroundColor: 'white',
      padding: '0.5rem',
      margin: '1rem 0rem 2rem'
    },
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '10px',
      padding: '1.25rem 1rem',
      color: 'white',
      backgroundColor: '#2d8b1acf',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.primary',
      height: 'unset',
      width: 'auto',
      borderRadius: '10px',
      borderColor: 'white',
      borderWidth: '2px'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'primary',
      color: 'white',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center',
      marginBottom: '0rem'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
