export default {
  heading: 'Source Sans Pro, sans-serif',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Special Elite, sans-serif',
  googleFonts: [
    'Special Elite',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Source Sans Pro:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
